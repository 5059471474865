var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Item", {
        attrs: {
          list: _vm.list,
          currentDrag: _vm.currentDrag,
          targetDrag: _vm.targetDrag,
          dragstart: _vm.dragstart,
          drag: _vm.drag,
          drop: _vm.drop,
          dragover: _vm.dragover,
          "can-drag": _vm.canDrag,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }