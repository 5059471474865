<template>
  <div>
    <Item
      :list="list"
      :currentDrag="currentDrag"
      :targetDrag="targetDrag"
      :dragstart="dragstart"
      :drag="drag"
      :drop="drop"
      :dragover="dragover"
      :can-drag="canDrag"
    />
  </div>
</template>

<script>
import Item from './item'
export default {
  name: 'DragManage',
  components: {
    Item,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    hierarchy: {
      type: Number,
      default: () => 0,
    },
    canDrag: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      currentDrag: {
        type: null,
        item: {},
      },
      targetDrag: {
        type: null,
        item: {},
      },
    }
  },
  methods: {
    dragstart(e, payload) {
      if (!this.canDrag) return false
      e.dataTransfer.dropEffect = 'move'
      console.log('dragstart', payload)
    },
    drag(e, payload) {
      if (!this.canDrag) return false
      console.log('drag', payload)
    },
    drop(e, payload) {
      if (!this.canDrag) return false
      console.log('drop', payload)
    },
    dragover(e, payload) {
      if (!this.canDrag) return false
      console.log('dragover', payload)
      this.targetDrag = payload
      e.preventDefault()
    },
  },
}
</script>

<style lang="stylus" scoped></style>